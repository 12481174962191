import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Zoom } from 'swiper/modules';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import ReactPlayer from 'react-player/file';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/zoom';

export type PhotoSwiperProps = {
  photoUrls: string[];
  videoUrls?: string[];
};

const MediaSwiper: FC<PhotoSwiperProps> = ({ photoUrls, videoUrls }) => {
  const { t } = useTranslation();

  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const currentSlideRef = useRef(0);

  useEffect(() => {
    if (fullScreen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [fullScreen]);

  let ind = 0;
  const PhotoSlider = ({
    fullScreenMode = false,
    initialSlide = 0,
    onClick,
  }: {
    fullScreenMode?: boolean;
    initialSlide?: number;
    onClick?: () => void;
  }) => (
    <Swiper
      modules={[Pagination, Zoom]}
      slidesPerView={'auto'}
      centeredSlides={true}
      zoom={true}
      pagination={{
        type: 'bullets',
        bulletActiveClass: 'bg-white opacity-90',
        horizontalClass: '!bottom-0',
      }}
      initialSlide={initialSlide}
      onSlideChange={(swiper) => {
        if (currentSlideRef) {
          currentSlideRef.current = swiper.activeIndex;
        }
      }}
      className="w-full h-full mb-2 "
    >
      {!photoUrls?.length && !videoUrls?.length && (
        <div className="absolute inset-0 flex items-center justify-center text-lg text-tgui-hint">{t('no_photos')}</div>
      )}

      {videoUrls?.map((videoUrl: string) => (
        <SwiperSlide key={ind++} className="flex">
          <ReactPlayer url={videoUrl} controls={true} width="100%" height="100%" />
        </SwiperSlide>
      ))}

      {photoUrls?.map((photoUrl: string) => (
        <SwiperSlide key={ind++} className="flex">
          <div className={`flex ${fullScreenMode ? 'swiper-zoom-container items-center' : ''}`}>
            <img
              src={photoUrl}
              alt={`photo ${ind++}`}
              className={`w-full ${fullScreenMode ? 'object-contain' : 'object-cover'}`}
              onClick={() => onClick && onClick()}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );

  return (
    <>
      <PhotoSlider fullScreenMode={false} initialSlide={currentSlideRef?.current} onClick={() => setFullScreen(true)} />

      {fullScreen && (
        <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-10 overflow-hidden">
          <button
            className="absolute top-5 right-[0.75rem] text-white text-xl z-20"
            onClick={() => setFullScreen(false)}
          >
            <AiOutlineCloseCircle size={26} className="opacity-50" />
          </button>

          <PhotoSlider fullScreenMode={true} initialSlide={currentSlideRef?.current} />
        </div>
      )}
    </>
  );
};

export default MediaSwiper;
