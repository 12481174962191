import FilterButton from '@/component/FilterButton.tsx';
import FilterSelect, { ModalSelectOption } from '@/component/ModalSelect.tsx';
import { FC, useEffect, useState } from 'react';
import { PostFilter, PostFilterValues, UNIT_TYPE } from '@/model/post.model.ts';
import { useTranslation } from 'react-i18next';
import FilterSlider from '@/component/FilterSlider.tsx';
import _ from 'lodash';
import { usePostService } from '@/service/post.service.ts';
import useEffectAfterMount from '@/hook/useEffectAfterMount.tsx';
import WebApp from '@twa-dev/sdk';

const ROOMS_MIN = 0;
const ROOMS_MAX = 15;
const PRICE_MIN = 0;
const PRICE_MAX = 500;

type PostFilterProps = {
  filter: PostFilter;
  onFilterChange: (filter: PostFilter) => void;
};

const PostFilters: FC<PostFilterProps> = ({ filter, onFilterChange }) => {
  const { t } = useTranslation();
  const postService = usePostService();

  const [filterValues, setFilterValues] = useState<PostFilterValues>({});
  const [loading, setLoading] = useState(true);

  const [locationOptions, setLocationOptions] = useState<ModalSelectOption[]>([]);
  const [typeOptions, setTypeOptions] = useState<ModalSelectOption[]>([]);

  const [showLocationModal, setShowLocationModal] = useState(false);
  const [showTypeModal, setShowTypeModal] = useState(false);
  const [showRoomsModal, setShowRoomsModal] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState(false);

  useEffectAfterMount(() => {
    setLoading(true);

    const username = WebApp.initDataUnsafe?.user?.username;
    Promise.all([postService.getPostFilterValues(), postService.getUserFilter(username || '')])
      .then(([filterValues, userFilter]) => {
        setFilterValues(filterValues);
        onFilterChange({ ...userFilter?.filter });
      })
      .finally(() => setLoading(false));
  }, [postService]);

  useEffect(() => {
    setLocationOptions(
      filterValues.locations?.map((location) => ({
        label: location || '-',
        value: location || '',
      })) || []
    );

    setTypeOptions(
      Object.values(UNIT_TYPE).map((type) => ({
        label: type === UNIT_TYPE.ROOM ? t('room') : t('villa'),
        value: type,
      }))
    );
  }, [filterValues]);

  return (
    <>
      {!loading && (
        <div className="flex gap-2 items-center p-2 bg-tgui-secondary-bg overflow-y-scroll no-scrollbar">
          <FilterButton
            title={t('location')}
            value={filter?.locations?.join(',')}
            onClick={() => setShowLocationModal(true)}
          />

          <FilterButton
            title={t('price')}
            value={
              filter?.priceRangeStart || filter?.priceRangeEnd
                ? `${filter?.priceRangeStart || PRICE_MIN} - ${filter?.priceRangeEnd || PRICE_MAX}`
                : t('all')
            }
            onClick={() => setShowPriceModal(true)}
          />

          <FilterButton
            title={t('type')}
            value={filter?.type && t(filter?.type === UNIT_TYPE.ROOM ? 'room' : 'villa')}
            onClick={() => setShowTypeModal(true)}
          />

          <FilterButton
            title={t('rooms')}
            value={
              filter?.roomsRangeStart || filter?.roomsRangeEnd
                ? `${filter?.roomsRangeStart || ROOMS_MIN} - ${filter?.roomsRangeEnd || ROOMS_MAX}`
                : t('all')
            }
            onClick={() => setShowRoomsModal(true)}
          />
        </div>
      )}

      {showLocationModal && (
        <FilterSelect
          title={t('location')}
          multi
          selected={locationOptions.filter((o) => filter?.locations?.includes(o.value as never))}
          options={locationOptions}
          onClose={() => setShowLocationModal(false)}
          onSelect={(options) => {
            setShowLocationModal(false);
            const locations = options?.map((o) => o.value);
            if (locations !== filter?.locations) {
              const updatedFilter = Object.assign({}, filter, { locations });
              onFilterChange(updatedFilter);
            }
          }}
        />
      )}

      {showTypeModal && (
        <FilterSelect
          title={t('type')}
          selected={typeOptions.filter((o) => filter?.type == o.value)}
          options={typeOptions}
          onClose={() => setShowTypeModal(false)}
          onSelect={(options) => {
            setShowTypeModal(false);
            const type = options?.length ? options[0].value : undefined;
            if (type !== filter?.type) {
              const updatedFilter = Object.assign({}, filter, { type });
              onFilterChange(updatedFilter);
            }
          }}
        />
      )}

      {showRoomsModal && (
        <FilterSlider
          title={t('rooms')}
          range
          min={ROOMS_MIN}
          max={ROOMS_MAX}
          unit={t('rooms')}
          defaultValue={[filter?.roomsRangeStart || ROOMS_MIN, filter?.roomsRangeEnd || ROOMS_MAX]}
          onValueSelect={(val) => {
            const newFilter = Object.assign({}, filter);
            if (val && Array.isArray(val)) {
              newFilter.roomsRangeStart = val[0];
              newFilter.roomsRangeEnd = val[1];
            } else {
              delete newFilter.roomsRangeStart;
              delete newFilter.roomsRangeEnd;
            }

            if (!_.isEqual(newFilter, filter)) {
              onFilterChange(newFilter);
            }
          }}
          onClose={() => setShowRoomsModal(false)}
        />
      )}

      {showPriceModal && (
        <FilterSlider
          title={t('price')}
          range
          min={PRICE_MIN}
          max={PRICE_MAX}
          unit={`${t('mil')}/${t('month_abbr')}`}
          defaultValue={[filter?.priceRangeStart || PRICE_MIN, filter?.priceRangeEnd || PRICE_MAX]}
          onValueSelect={(val) => {
            const newFilter = Object.assign({}, filter);
            if (val && Array.isArray(val)) {
              newFilter.priceRangeStart = val[0];
              newFilter.priceRangeEnd = val[1];
            } else {
              delete newFilter.priceRangeStart;
              delete newFilter.priceRangeEnd;
            }

            if (!_.isEqual(newFilter, filter)) {
              onFilterChange(newFilter);
            }
          }}
          onClose={() => setShowPriceModal(false)}
        />
      )}
    </>
  );
};

export default PostFilters;
